import React from "react";

import { ReactivatedSerializationFieldType, _Types } from "@reactivated";

import { SelectField, SelectOption } from "./SelectField";
import { TextField } from "./TextField";

function cleanValue(x: unknown) {
    if (typeof x === "string") return x;
    else return undefined;
}

export { SelectField, SelectOption, TextField };

export default function Widget({
    widget,
    name,
    help_text,
    label,
}: ReactivatedSerializationFieldType & {
    widget: _Types["globals"]["Widget"];
}) {
    if (widget.is_hidden) {
        return (
            <input
                type="hidden"
                name={widget.name}
                value={cleanValue(widget.value)}
            />
        );
    }
    switch (widget.tag) {
        case "django.forms.widgets.TextInput":
        case "django.forms.widgets.EmailInput":
        case "django.forms.widgets.Textarea":
            return (
                <TextField
                    {...widget.attrs}
                    defaultValue={cleanValue(widget.value)}
                    isRequired={widget.required}
                    name={name}
                    description={cleanValue(help_text)}
                    label={label}
                    type={"type" in widget ? widget.type : undefined}
                    multiline={widget.tag === "django.forms.widgets.Textarea"}
                />
            );
        case "django.forms.widgets.Select":
            return (
                <SelectField
                    {...widget.attrs}
                    label={label}
                    description={cleanValue(help_text)}
                    name={name}
                    isRequired={widget.required}
                    defaultSelectedKey={cleanValue(widget.value)}
                >
                    {widget.optgroups.flatMap((group) =>
                        group[1].map((opt) => {
                            const value = cleanValue(opt.value);
                            return (
                                <SelectOption
                                    key={value}
                                    id={value}
                                    value={opt}
                                >
                                    {opt.label}
                                </SelectOption>
                            );
                        }),
                    )}
                </SelectField>
            );
        default:
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
            console.warn(`Unknown widget type: ${(widget as any).tag}`);
            return (
                <input
                    type="hidden"
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
                    value={cleanValue((widget as any).value)}
                    name={name}
                />
            );
    }
}
